import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import { ItemsProvider } from './context/ItemsContext';
import { AuthProvider } from "./components/authentication/AuthContext";
import { BillProvider } from "./context/BillContext";
import { GstDiscountProvider } from "./context/GstDiscountContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Router>
      <AuthProvider>
        <ItemsProvider>
          <BillProvider>
          <GstDiscountProvider>
          <App />
          </GstDiscountProvider>
          </BillProvider>
        </ItemsProvider>
      </AuthProvider>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
